import {
    makeStyles,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import api from "../../../dataProvider/api";
import NewQueryDialog from "../CreateQuery/NewQuery";
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router";
import getCorrectDate from "../../../helpers/getCorrectDate";
import { useContext } from "react";
import ServicesContext from "../../../services/ServicesContext";
  
const useStyles = makeStyles((theme) => ({
    row: {
        cursor: "pointer",
    },
    createQueryButton: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "10px",
    },
    noQueriesButton: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%",
      marginRight: "-8px",
      marginBottom: "10px",
    },
}));
  
const QueryList = ({token}: { token: string}) => {
    const classes = useStyles();
    const history = useHistory();
    const { queryService } = useContext(ServicesContext);
  
    useEffect(() => {
      (async () => {
        if (queryService.currentDatabase && queryService.currentDatabase.id) {
          const queries = await api.getQueries(queryService.currentDatabase.id);
          queryService.queriesStore = queries;
          queryService.clearCurrentQuery();
        }
      })();
    }, []);
  
    useEffect(() => {
      (async () => {
        if (queryService.currentQueryVersion !== undefined)
          await api.setSqlToAQB(queryService.currentQueryVersion.id, token)
      })();
    }, [queryService.currentQueryVersion]);
  
    const setQueryVersion = (id: number) => {
      if (queryService.currentQuery && queryService.currentQuery.id === id)
        return;
  
      queryService.setNewQuery(queryService.queriesStore.filter((q) => q.id === id)[0]);
    };

    const openButton = (handleClickOpen: () => void) => (
      <div className={`${!(queryService.queriesStore && queryService.queriesStore.length > 0) ? classes.createQueryButton : classes.noQueriesButton}`}>
        <Button onClick={handleClickOpen} startIcon={<AddIcon />} color="primary">Create query</Button>
      </div>
    );

    const onRowClick = (id: number) => {
      setQueryVersion(id);
      history.push(`/${id}`);
    }
    
    if (queryService.queriesStore.length === 0)
      return <NewQueryDialog openButton={openButton} />;

    return (
      <div>
        <NewQueryDialog openButton={openButton} />
        <Paper>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Query name</b></TableCell>
                            <TableCell><b>Last change</b></TableCell>
                            <TableCell><b>Last execution</b></TableCell>
                            <TableCell><b>Last export</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {queryService.queriesStore.map((r, i) => (
                            <TableRow key={i} hover className={classes.row} onClick={() => onRowClick(r.id)}>
                                <TableCell>{r.name}</TableCell>
                                <TableCell>{getCorrectDate(r.lastChange)}</TableCell>
                                <TableCell>{getCorrectDate(r.lastExecution)}</TableCell>
                                <TableCell>{getCorrectDate(r.lastExport)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
      </div>
    );
};
  
export default observer(QueryList);
