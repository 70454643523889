import React, { FC, useContext, useEffect, useState } from "react";
import api from "../../../dataProvider/api";
import { CustomDialog } from "../../common";
import { Query } from "../dtoInterfaces";
import { GetTokenForQueryBuilder } from "../../../aqb/helper";
import QueryEditor from "../QueryEditor";
import ServicesContext, { Context } from "../../../services/ServicesContext";
import { observer } from "mobx-react-lite";
import Header from "../QueryHeader";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogActions: {
      width: "80%",
  },
  dialogTitle: {
    paddingBottom: 0,
  },
}));

const NewQueryDialog: FC<NewQueryDialogProps> = ({ openButton }) => {
  const [open, setOpen] = useState(false);
  const [queryName, setQueryName] = useState<string>("");
  const [queryString, setQueryString] = useState<string | null>(null);
  const [clientQuery, setClientQuery] = useState<string | null>(null);
  const [canSave, setCanSave] = useState<boolean>(true);

  const classes = useStyles();

  const queryService = useContext<Context>(ServicesContext).queryService;

  useEffect(() => {
    setCanSave(
      !queryString ||
      !clientQuery ||
      queryString !== clientQuery ||
      queryName === ""
    );
  }, [queryString, clientQuery, queryName, setCanSave]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (save: boolean) => async () => {
    if (save && queryService.currentDatabase) {
      const response = await api.createQuery(queryName, GetTokenForQueryBuilder(queryService.currentDatabase.name), queryService.currentDatabase.id);
      queryService.clearCurrentQuery();

      if (queryService.currentDatabase && queryService.currentDatabase.id) {
        const queries = await api.getQueries(queryService.currentDatabase.id);
        queryService.queriesStore = queries;
        queryService.setNewQuery(queries.find(q => q.id === (response as Query).id) as Query);
      }
    }
    else if (queryService.currentQueryVersion) {
        await api.setSqlToAQB(queryService.currentQueryVersion.id, GetTokenForQueryBuilder(queryService.currentDatabase!.name));
    }

    setOpen(false);
  };

  return (
    <div>
      <CustomDialog
        fullScreen
        openButton={openButton}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        textButton={"Create a new query"}
        classes={ {dialogActions: classes.dialogActions, dialogTitle: classes.dialogTitle} }
        actions={<Header queryName={queryName} setQueryName={setQueryName} handleClose={handleClose} canSave={canSave}/>}
        dialogContent={
          <QueryEditor
            databaseType={queryService.currentDatabase!.databaseType}
            qbToken={GetTokenForQueryBuilder(queryService.currentDatabase!.name)}
            header={<div></div>}
            setQuery={setQueryString}
            setClientQuery={setClientQuery}
          />
        }
        dialogTitle={"Create new query"}
      />
    </div>
  );
};

interface NewQueryDialogProps {
  openButton?: (handleClickOpen: () => void) => JSX.Element;
}

export default observer(NewQueryDialog);
