import React, { useContext } from "react";
import { SignIn, SignUp, Home, SsoAuth } from "../pages";
import QueryInfo from "../components/query/QueryInfo/QueryInfo";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Routes } from "../configuration";
import ServicesContext, { Context } from "../services/ServicesContext";
import RedirectToHtmlPage from "../pages/Index/index";

export default function () {
  return (
    <Switch>
      <UnauthenticatedRoute path={Routes.signIn} exact={true}>
        <SignIn />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path={Routes.index} exact={true}>
        <RedirectToHtmlPage />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path={Routes.signUp} exact={true}>
        <SignUp />
      </UnauthenticatedRoute>
      {/* <UnauthenticatedRoute path={Routes.ssoAuth} exact={true}>
        <SsoAuth />
      </UnauthenticatedRoute> */}
      <AuthenticatedRoute path={Routes.list} exact={true}>
        <Home />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={Routes.queryInfo} exact={true}>
        <QueryInfo />
      </AuthenticatedRoute>
    </Switch>
  );
}

const AuthenticatedRoute = (props: any) => {
  const { children, ...rest }: { children: JSX.Element } = props;
  const accountService = useContext<Context>(ServicesContext).accountService;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (accountService.isTokenExist())
          return children;
        else {
          document.location.href = "https://www.auth.activedbsoft.com?redirectUrl=" + document.location.href;
          return <></>;
        }
      }}
    />
  );
};

const UnauthenticatedRoute = (props: any) => {
  const { children, ...rest }: { children: JSX.Element } = props;
  const accountService = useContext<Context>(ServicesContext).accountService;

  return (
    <Route
      {...rest}
      render={(props) => {
        return !accountService.isTokenExist() ? children : <Redirect to="/list" />
      }}
    />
  );
};
