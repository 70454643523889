import { Button, makeStyles } from "@material-ui/core/";
import { PlayArrow } from "@material-ui/icons";
import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import api from "../../../dataProvider/api";
import { CustomDialog } from "../../common/";
import { Version } from "../dtoInterfaces";
import ResultGrid from "./ResultGrid";

const useStyles = makeStyles((theme) => ({
  newQuery: {
    "& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded": {
      maxWidth: "90%",
      width: "90%",
    },
  },
}));

const ExecuteQuery: FC<ExecuteQueryDialogProps> = ({
  canExecuteQuery,
  currentQueryVersion,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (save: boolean) => async () => {
    setOpen(false);
  };

  const openButton = (handleClickOpen: () => void) => (
    <Button
      variant="contained"
      onClick={handleClickOpen}
      disabled={!canExecuteQuery}
      color="default"
      startIcon={<PlayArrow />}
    >
      Execute
    </Button>
  );

  return (
    <CustomDialog
      openButton={openButton}
      open={open}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      classes={{root: classes.newQuery}}
      actions={
        <Button onClick={handleClose(false)} color="primary">
          Exit
        </Button>
      }
      dialogContent={currentQueryVersion ? <ResultGrid getTypeColumns={api.getTypeColumns} executeQuery={api.executeQuery} criteriaBuilder/> : <div></div>}
      dialogTitle={""}
    />
  );
};

interface ExecuteQueryDialogProps {
  canExecuteQuery: boolean;
  currentQueryVersion: Version | undefined;
}

export default observer(ExecuteQuery);
