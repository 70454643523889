
export enum ParamType {
  Int = 11,
  Int64 = 12,
  Boolean = 3,
  DateTime = 6,
  Decimal = 7,
  Double = 8,
  String = 16,
  Object = 13,
  AnsiString = 0
};
