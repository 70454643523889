import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowData, GridSortItem } from "@material-ui/data-grid";
import api from "../../../dataProvider/api";
import { ParamType } from "../../../enums";
import { GetTokenForQueryBuilder } from "../../../aqb/helper";
import { observer } from "mobx-react";
import ServicesContext, { Context } from "../../../services/ServicesContext";
import CriteriaBuilder from "../../../aqb/CriteriaBuilder";
import { Snackbar, Typography } from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  error: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        marginRight: 10
      }
    },
    "& > p": {
      marginBottom: "70px"
    }
  }
});

const ResultGrid = ({getTypeColumns, executeQuery, criteriaBuilder, isSubQuery = false}: ResultGridProps) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowData[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [sortItem, setSortItem] = useState<GridSortItem>();
  const [sqlValue, setSqlValue] = useState();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [parametersErrorMessage, setParametersErrorMessage] = useState<string>();

  const queryService = useContext<Context>(ServicesContext).queryService;
  const parameters = queryService.currentQueryVersion?.parameters;
  const queryVersionId = queryService.currentQueryVersion?.id;
  const parametersValues = parameters?.map(p => p.value).join();

  const classes = useStyles();

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSortChange = (item: GridSortItem) => {
    setSortItem(item);
  }

  useEffect(() => {
    if (!queryVersionId)
      return;
    (async () => {
        setLoading(true);
        const result = await getTypeColumns(GetTokenForQueryBuilder(queryService.currentDatabase!.name), isSubQuery) as Array<{name: string, type: ParamType}>;
        if (typeof (result) === "string")
          return;

        setColumns(result.map(c => {
          if (c.name == "id")
            return { field: `${c.name}`, headerName: `${c.name}`, width: 150, hide: true};

          return {field: `${c.name}`, headerName: `${c.name}`, width: 150} 
        }));
        setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setParametersErrorMessage(undefined);
      const notSavedParameters = parameters?.filter(p => !p.saved); 
      if (notSavedParameters && queryVersionId) {
        const parametersSaveResponse = await api.saveParameters(notSavedParameters, queryVersionId);
        if (parametersSaveResponse && !parametersSaveResponse.succeeded) {
          console.error(parametersSaveResponse.message);
          setParametersErrorMessage(parametersSaveResponse.message as string);
        }
        if (queryService.currentQueryVersion?.id !== undefined) {
          const newParameters = await api.getParametersByQuery(queryService.currentQueryVersion?.id)
          queryService.setCurrentQueryParameters(newParameters);
        }
      }
    })();
  }, [])

  useEffect(() => {
    (async () => {
        if (!queryVersionId)
          return;

        setLoading(true);

        const resultData = await executeQuery(queryVersionId, GetTokenForQueryBuilder(queryService.currentDatabase!.name), (page * 10), (page * 10 + 10), JSON.stringify(parameters), isSubQuery, sortItem?.field, sortItem?.sort);
        
        if (resultData && resultData.data) {
          setRows(resultData.data);
          setRowCount(resultData.total);
        }
        else {
          setErrorMessage(resultData.message)
        }
        setLoading(false);
        await queryService.updateCurrentQuery();
    })();
  }, [page, queryVersionId, parameters, parametersValues, sortItem, sqlValue]);

  if (columns === null) {
    return (<div></div>);
  }

  if (errorMessage)
    return (
      <div className={classes.error}>
        <div>
          <ErrorIcon color="error" />
          <Typography align="center" variant="h4">Error</Typography>
        </div>
        <Typography align="center" >{errorMessage}</Typography>
      </div>
    );
  
  return (
    <div style={{ height: 650, width: "100%" }}>
      {criteriaBuilder && 
        <CriteriaBuilder 
          name={GetTokenForQueryBuilder(queryService.currentDatabase!.name)} 
          databaseType={queryService.currentDatabase!.databaseType} 
          handlerCbChanges={setSqlValue}
        />
      }
      <DataGrid
        rowHeight={32}
        disableMultipleColumnsSorting={true}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowCount={rowCount}
        pagination
        disableSelectionOnClick
        paginationMode="server"
        onPageChange={handlePageChange}
        sortingMode="server"
        onSortModelChange={e => handleSortChange(e[0])}
        loading={loading}
        disableColumnFilter={true}
      />
      <Snackbar
        open={parametersErrorMessage !== undefined}
        message={parametersErrorMessage}
      />
    </div>
  );
};


interface ResultGridProps {
  getTypeColumns: (token: string, isSubQuery: boolean) => any;
  executeQuery: (queryVersionId: number, token: string, start: number, end: number, parameters: string, isSubQuery: boolean, sortName?: string, order?: string | undefined | null) => any;
  criteriaBuilder?: boolean,
  isSubQuery?: boolean
}

export default observer(ResultGrid);
