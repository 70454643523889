import React from "react";
import QueryBuilder from "../../../aqb/QueryBuilder";

const QueryEditor = ({
  qbToken,
  setQuery,
  setClientQuery,
  databaseType,
  header,
  setQueryBuilderReady,
}: {
  qbToken: string;
  setQuery: React.Dispatch<React.SetStateAction<string | null>>;
  setClientQuery: React.Dispatch<React.SetStateAction<string | null>>;
  databaseType: number,
  header: JSX.Element
  setQueryBuilderReady?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const sqlTextHandler = (clientSQL: any, SQL: string) => {
    setClientQuery(clientSQL);
    setQuery(SQL);
  };

  return (
    <div>
      {header}
      <QueryBuilder
        name={qbToken}
        databaseType={databaseType}
        sqlTextHandler={sqlTextHandler}
        setQueryBuilderReady={setQueryBuilderReady}
      />
    </div>
  );
};

export default QueryEditor;
