export const getFileName = (response: Response) => {
  const header = response.headers.get("content-disposition");
  if (header == null)
      return "fileName";

  if (header && header.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(header);
      if (matches != null && matches[1]) { 
          return matches[1].replace(/['"]/g, "");
      }
  }
}