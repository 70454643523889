import React, { FC, useContext, useEffect, useState } from "react";
import { Button, makeStyles, TextField } from "@material-ui/core";
import { GetTokenForQueryBuilder } from "../../../aqb/helper";
import api from "../../../dataProvider/api";
import ServicesContext, { Context } from "../../../services/ServicesContext";
import { CustomDialog } from "../../common";
import { Version } from "../dtoInterfaces";
import QueryEditor from "../QueryEditor";
import { observer } from "mobx-react";
import QueryService from "../../../services/queryService/queryService";
import { Edit } from "@material-ui/icons";
import Header from "../QueryHeader";

const useStyles = makeStyles((theme) => ({
  newQuery: {
    "& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded": {
      maxWidth: "100%",
      width: "100%",
    }
  },
  dialogTitle: {
    paddingBottom: 0,
  },
}));

interface IQueryActionProps {
  updateCurrentQueryInfo: () => Promise<void>,
}

const EditQuery = (props: IQueryActionProps) => {
  const [canSave, setCanSave] = useState<boolean>(false);
  const queryService = useContext<Context>(ServicesContext).queryService;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const openButton = (handleClickOpen: () => void) => (
    <Button
      onClick={handleClickOpen}
      variant="contained"
      startIcon={<Edit />}
    >
      Edit
    </Button>
  );

  const handleClose = (save: boolean) => async () => {
    if (save && queryService.currentQuery !== undefined && queryService.currentQueryVersion !== undefined) {
      const response: Version = await api.createNewQueryVersion(queryService.currentQueryVersion.id, queryService.currentQuery.name, GetTokenForQueryBuilder(queryService.currentDatabase!.name));
      const res = queryService.currentQuery?.queryVersions.push(response);
      queryService.setNewQueryVersion(response);
      await props.updateCurrentQueryInfo();
    }

    setOpen(false);
  };

  return (
    <div>
      <CustomDialog
        open={open}
        openButton={openButton}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        classes={{ root: classes.newQuery, dialogTitle: classes.dialogTitle }}
        fullScreen
        dialogContent={
          <Editor
            queryService={queryService}
            setCanSave={setCanSave}
          />
        }
        actions={<Header handleClose={handleClose} canSave={canSave} />}
        dialogTitle={`Edit query ${queryService.currentQuery?.name}`}
      />
    </div>
  );
};

const Editor = ({
  queryService,
  setCanSave
}: {
  queryService: QueryService;
  setCanSave: (canSave: boolean) => void,
}) => {
  const queryVersionId = queryService.currentQueryVersion?.id;
  const [queryString, setQueryString] = useState<string | null>(null);
  const [clientQuery, setClientQuery] = useState<string | null>(null);
  const [queryBuilderReady, setQueryBuilderReady] = useState<boolean>(false);

  useEffect(() => {
    setCanSave(
      !queryString ||
      !clientQuery ||
      queryString !== clientQuery ||
      queryService.currentQueryVersion === undefined
    );
  }, [queryString, clientQuery, setCanSave, queryService.currentQueryVersion]);

  useEffect(() => {
    if (queryVersionId)
      (async () => {
        await api.setSqlToAQB(queryVersionId, GetTokenForQueryBuilder(queryService.currentDatabase!.name))
        
        //@ts-ignore
        if (AQB?.Web?.QueryBuilder?.update) AQB.Web.QueryBuilder.update();
      })();
  }, [queryVersionId]);

  return (
    <QueryEditor
      databaseType={queryService.currentDatabase!.databaseType}
      qbToken={GetTokenForQueryBuilder(queryService.currentDatabase!.name)}
      setQuery={setQueryString}
      setClientQuery={setClientQuery}
      header={<div></div>}
      setQueryBuilderReady={setQueryBuilderReady}
    />
  );
}


export default observer(EditQuery);
