import { Button, MenuItem, Select, TextField, TextFieldProps , makeStyles, TableRow, TableBody, Table, TableContainer, Paper, TableCell, TableHead} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { ParamType } from "../../../enums";
import ServicesContext, { Context } from "../../../services/ServicesContext";
import { Parameter, Query } from "../dtoInterfaces";
import { observer } from "mobx-react";
import { getFormatedDateFromDate } from "../../../helpers/timeHelper";

const useStyles = makeStyles((theme) => ({
  parameters: {
    display: "flex", 
    flexDirection: "column", 
    alignItems: "flex-start",
  }
}));

const Parameters = () => {
  const queryService = useContext<Context>(ServicesContext).queryService;
  const parameters = queryService.currentQueryVersion?.parameters;
  const classes = useStyles();

  return (
    <div className={classes.parameters}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Parameter</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parameters?.map((param, i) =>
            <TableRow>
              <TableCell>{param.name}</TableCell>
              <TableCell align="right">{getParamComponent(param, i, parameters)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default observer(Parameters); 

const getParamComponent = (
  param: Parameter,
  i: number,
  parameters: Parameter[],
) => {

  const setValue = (paramValue: any, parameter: Parameter) => {
    if (parameter.saved){
      parameters = parameters.map(p => Object.assign(p, {
        saved: false,
      }));
      parameter.value = paramValue;
    }
    else
      parameter.value = paramValue;
  };

  const setValueTextField = (parameter: Parameter) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value === "") {
      setValue(undefined, parameter);
      return;
    }
    setValue(e.target.value, parameter);
  };

  const setValueSelectField = (parameter: Parameter) => (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setValue(e.target.value, parameter);
  };

  const propsObj: TextFieldProps = {
    key: i,
    label: param.name + " " + param.compareOperator,
    type: "number",
    onChange: e => setValueTextField(param)(e),
    defaultValue: param.value,
  };


  const dateNow = new Date();
  const createFormateDate = (dateValue: Date) => {
    const dateTime = getFormatedDateFromDate(dateValue).split(" ");
    const date = dateTime[0].split(".");
    const time = dateTime[1].split(":");
    
    return `${date[2]}-${date[1]}-${date[0]}T${time[0]}:${time[1]}`;
  };

  const createByType = (dataType: number) => {
    switch (dataType) {
      case ParamType.Double:
        return (
          <TextField {...propsObj} />);
      case ParamType.Int:
        return (
          <TextField {...propsObj} />);
      case ParamType.Int64:
        return (
          <TextField {...propsObj} />);
      case ParamType.Decimal:
        return (
          <TextField {...propsObj} />);
      case ParamType.Boolean:
        return (
          <Select
            key={i}
            label={param.name + " " + param.compareOperator}
            onChange={(e: any) => setValueSelectField(param)(e)}
            defaultValue={false}
          >
            <MenuItem value="true">Да</MenuItem>
            <MenuItem value="false">Нет</MenuItem>
          </Select>);
      case ParamType.DateTime:
        return (
          <TextField
            {...propsObj}
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={param.value ? createFormateDate(new Date(param.value)) : createFormateDate(dateNow)}
            onFocus={e => setValueTextField(param)(e)}
          />);
      case ParamType.String:
        return (<TextField {...propsObj} type="text" />);
      case ParamType.AnsiString:
        return (
          <TextField {...propsObj} type="text" />);
      default:
        return <TextField {...propsObj} type="text" />;
    };
  };

  return (
    <div>{createByType(param.dataType)}</div>
  )
};