import { Button, makeStyles, Paper, Typography, IconButton} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import React, { useContext, useEffect, useState} from "react";
import Parameters from ".";
import QueryService from "../../../services/queryService/queryService";
import ServicesContext, { Context } from "../../../services/ServicesContext";
import { CustomDialog } from "../../common";

const nextParametersVersion = 1;
const previousParametersVersion = -1;

const useStyles = makeStyles((theme) => ({
  parametersBlock: {
    marginLeft: 8,
    display: "flex", 
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  parameters: {
    cursor: "pointer",
    display: "flex", 
    flexDirection: "column", 
    alignItems: "flex-start",
    border: "1px solid lightgray",
    borderRadius: "5px",
    minHeight: 80,
    overflowX: "hidden",
    overflowY: "auto",
    minWidth: "100%",
    "& > .MuiPaper-root": {
      width: "100%",
      marginBottom: 2
    }
  },
  dialogActions: {
    justifyContent: "center"
  },
  dialogRoot: {
    marginLeft: "15%",
    marginRight: "15%",
  },
  parameterLine: {
    paddingLeft: "15px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  parametersChange: {
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "70px"
  },
  parametersContainer: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
  }
}));

interface IParameterDisplayProps {
  changeParametersVersion: (value: number) => void,
  parameterVersionsCount: number
}

const ParametersDisplay = (props: IParameterDisplayProps) => {
  const queryService = useContext<Context>(ServicesContext).queryService;
  const parameters = queryService.currentQueryVersion?.parameters;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  if (parameters === undefined || parameters.length === 0)
    return null;


  return (
    <div className={classes.parametersContainer}>
      <div className={classes.parametersBlock}>
        <div className={classes.parameters} onClick={() => setOpen(true)}>
          {parameters?.map(p => <Paper className={classes.parameterLine}><Typography>{p.name}: {p.value}</Typography></Paper>)}
        </div>
        <div>
          <ChangeParametersDialog queryService={queryService} open={open} setOpen={setOpen}/>
        </div>
      </div>
      <div className={classes.parametersChange}>
          <IconButton
            disabled={!props.parameterVersionsCount}
            size="small"
            color="primary"
            onClick={() => props.changeParametersVersion(previousParametersVersion)}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            disabled={!props.parameterVersionsCount}
            size="small"
            color="primary"
            onClick={() => props.changeParametersVersion(nextParametersVersion)}
          >
            <ArrowForwardIos />
          </IconButton>
      </div>
    </div>
  );
};

const ChangeParametersDialog = ({queryService, open, setOpen}: {queryService: QueryService, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const classes = useStyles();
  const [prevParams, setPrevParams] = useState<Array<{name: string, value: string}>>([]);
  const parameters = queryService.currentQueryVersion?.parameters;

  useEffect(() => {
    if (parameters)
      setPrevParams(parameters?.map(p => ({name: p.name, value: p.value})));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (save: boolean) => async () => {
    if (!save)
      prevParams.forEach(pp => queryService.currentQueryVersion!.parameters.find(p => p.name === pp.name)!.value = pp.value);
    
    setOpen(false);
  };

  const openButton = (handleClickOpen: () => void) => <div></div>

  return (
    <CustomDialog
      openButton={openButton}
      open={open}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      classes={{dialogActions: classes.dialogActions, root: classes.dialogRoot}}
      actions={
        <div>
          <Button onClick={handleClose(true)} color="primary">
            Save
          </Button>
          <Button onClick={handleClose(false)} color="primary">
            Cancel
          </Button>
        </div>
      }
      dialogContent={<Parameters />}
      dialogTitle={"Parameters values"}
    />
  );
}

export default ParametersDisplay;