import { Box, Tab, Tabs, makeStyles, Typography, TableBody, TableRow, TableCell, TableContainer, Paper } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { observer } from "mobx-react";
import React, { useState, useContext } from "react";
import ServicesContext, { Context } from "../../../services/ServicesContext";
import DefaultDatagrid from "../../common/DefaultDatagrid";
import ShowDialog from "../../common/ShowDialog";
import { UsedDatabaseObject, UsedDatabaseObjectField } from "../dtoInterfaces";

const useStyles = makeStyles((theme) => ({
  queryStatistics: {
    display: "flex",
    flexDirection: "column",
    "& .topTables": {
      display: "flex",
      "& .MuiTableContainer-root": {
        height: "35vh"
      },
      "& > :first-child": {
        marginRight: 35,
        width: "50%",
      },
      "& > :last-child": {
        width: "100%",
      }
    },
    "& > :last-child": {
      marginTop: 30,
      height: "40vh"
    },
    "& .TableHeader": {
      background: "#ebf3f9"
    }
  }
}));

const QueryStatistics = () => {
  return <ShowDialog
    dialogTitle="QueryName Info"
    openButtonName="INFO"
    openButtonIcon={<Info />}
    content={<Content />}
    hideOverflow
  />;
};

const Content = observer(() => {
  const queryService = useContext<Context>(ServicesContext).queryService;
  const classes = useStyles();

  if (queryService.currentQueryVersion === undefined)
    return null;

  return (
    <div className={classes.queryStatistics}>
      <div className="topTables">
        <div>
          <Paper className="TableHeader" ><Typography>Used tables</Typography></Paper>
          {
            queryService.currentQueryVersion!.statistic.usedDatabaseObjects && queryService.currentQueryVersion!.statistic.usedDatabaseObjects[0] ?
            <List<UsedDatabaseObject> items={queryService.currentQueryVersion!.statistic.usedDatabaseObjects} getElementToView={(item) => item.objectName} />
            // <DefaultDatagrid 
            //   rows={queryService.currentQueryVersion!.Statistic.UsedDatabaseObjects.map((ic, i) => ({id: i, ...ic}))}
            //   total={queryService.currentQueryVersion!.Statistic.UsedDatabaseObjects.length}
            //   columns={[{field: "id", hide: true }, ...Object.entries(queryService.currentQueryVersion!.Statistic.UsedDatabaseObjects[0]).map((ic) => ({ field: ic[0], width: 200 }))]}
            // />
            :
            <Typography variant="h6">Empty</Typography>
          }
        </div>
        <div>
          <Paper className="TableHeader" ><Typography >Table fields</Typography></Paper>
          {
            queryService.currentQueryVersion.statistic.usedFields && queryService.currentQueryVersion.statistic.usedFields[0] ?
            <List<UsedDatabaseObjectField> items={queryService.currentQueryVersion!.statistic.usedFields} getElementToView={(item) => item.metadataFieldName + ": " + item.metadataFieldFieldType} />
            // <DefaultDatagrid 
            //   rows={queryService.currentQueryVersion!.Statistic.UsedFields.map((ic, i) => ({id: i, ...ic}))}
            //   total={queryService.currentQueryVersion!.Statistic.UsedFields.length}
            //   columns={[{field: "id", hide: true }, ...Object.entries(queryService.currentQueryVersion!.Statistic.UsedFields[0]).map((ic) => ({ field: ic[0], width: 200 }))]}
            // />
            :
            <Typography variant="h6">Empty</Typography>
          }
        </div>
      </div>
      <div>
        <Paper className="TableHeader" ><Typography>Output expressions</Typography></Paper>
        {
            queryService.currentQueryVersion!.statistic.infoColumns && queryService.currentQueryVersion!.statistic.infoColumns[0] ?
            <DefaultDatagrid 
              rows={queryService.currentQueryVersion!.statistic.infoColumns.map((ic, i) => ({id: i, ...ic}))}
              total={queryService.currentQueryVersion!.statistic.infoColumns.length}
              columns={[{field: "id", hide: true }, ...Object.entries(queryService.currentQueryVersion!.statistic.infoColumns[0]).map((ic) => ({ field: ic[0], width: 200 }))]}
            />
            :
            <Typography variant="h6">Empty</Typography>
          }
      </div>
    </div>
  );
});


function List<T>({items, getElementToView}: ListProps<T>) {
  return (
    <TableContainer component={Paper} >
      <TableBody>
        {items.map((item: any, i: number) => (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              {getElementToView(item)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
}

interface ListProps<T> {
  items: T[], 
  getElementToView: (item : T) => JSX.Element | string
}

export default observer(QueryStatistics);
