import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowData } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";

const useStyles = makeStyles({
  datagrid: {
    width: "100%",
    height: "39vh",
  }
});

const DefaultDatagrid = ({rows, total, columns} : {rows: GridRowData[], total: number, columns: GridColDef[]}) => {
  const [page, setPage] = useState(0);
  const classes = useStyles();

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  if (columns === null) {
    return (<div></div>);
} 
  
  return (
    <div className={classes.datagrid}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowCount={total}
        page={page}
        pagination
        disableSelectionOnClick
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default observer(DefaultDatagrid);
