import React, { ChangeEvent, KeyboardEventHandler, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/styles";
import { ClickAwayListener, Snackbar } from "@material-ui/core";
import api from "../../dataProvider/api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
    color: "white",
    fontSize: 40,
    opacity: 1,
    borderBottom: 0,
    "&:before": {
      borderBottom: 0
    },
    "&div": {
    }
  },
  disabled: {
    borderBottom: 0,
    "& .MuiInput-underline": {
      content: ""
    },
    "&:before": {
      borderBottom: 0
    }
  },
  btnIcons: {
    marginLeft: 10
  },
  marginDense: {
    paddingTop: "8px",
    paddingBottom: "8px",
  }
}));

const EditableTextField = ({initialValue, handleUpdate}: {initialValue: string, handleUpdate: (value: string) => Promise<string | undefined>}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [mouseOver, setMouseOver] = useState<boolean>(true);
  const classes = useStyles({editMode: editMode});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway')
      return;

    setOpen(false);
  };
  

  useEffect(() => {
    setValue(initialValue);
    setEditMode(false);
    setMouseOver(true);
  }, [initialValue])

  const handleChange = (event: ChangeEvent<{name: string, value: string}>) => {
    setValue(event.target.value);
  };

  const handleClick = () => {
    setEditMode(true);
    setMouseOver(false);
  };

  const handleUpdateName = async () => {
    if (initialValue === value) {
      setMouseOver(true);
      setEditMode(false);
      return;
    }

    const error = await handleUpdate(value);
    if (error) {
      setMessage(error);
      setOpen(true);
      setValue(initialValue);
      return;
    }
    
    setMessage("Successfully updated name");
    setOpen(true);
  }

  const handleUpdateNameByKey = async (e: any) => {
    if (e.charCode !== 13) 
      return;

    await handleUpdateName();
  }

  return (
    <div className={classes.container}>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => {
        setEditMode(false);
        setMouseOver(true);
        handleUpdateName();
      }}>
      <TextField
        onClick={handleClick}
        size="small"
        name="Name"
        defaultValue={value}
        margin="normal"
        error={value === ""}
        value={value}
        onChange={handleChange}
        disabled={!editMode}
        variant="filled"
        className={classes.textField}
        onKeyPress={handleUpdateNameByKey}
        InputProps={{
          classes: {
            disabled: classes.disabled,
            marginDense: classes.marginDense,
            inputMarginDense: classes.marginDense,
          },
          endAdornment: mouseOver ? (
            <InputAdornment position="end">
              <IconButton color="primary">
                <Edit fontSize="small"/>
              </IconButton>
            </InputAdornment>
          ) : (
            ""
          )
        }}
      />
    </ClickAwayListener>
    <Snackbar
        open={open} 
        autoHideDuration={2000} 
        onClose={handleClose}
        message={message}
      />
    </div>
  );
}

export default EditableTextField;
