import React from "react";

const RedirectToHtmlPage = () => {
    window.location.href="main.html";

    return(
        <div>
        </div>
    )
}

export default RedirectToHtmlPage;