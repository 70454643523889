import React, { useContext, useState } from "react";
import { ListItemIcon, ListItemText, makeStyles, MenuItem, MenuList, Select, Typography } from "@material-ui/core";
import { FC } from "react";
import ServicesContext, { Context } from "../services/ServicesContext";
import QueryList from "./query/QueryList/QueryList";
import { GetTokenForQueryBuilder } from "../aqb/helper";
import { observer } from "mobx-react";
import getDatabaseIcon from "../helpers/getDatabaseIcon";

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
  },
  tab: {
    border: `2px solid ${theme.palette.primary.main}`,
    WebkitBorderBottomRightRadius: "5px",
    minHeight: "77vh",
  },
  root: {
    display: "flex",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
  },
  tabsContainer: {
    width: "100%",
    border: `2px solid ${theme.palette.primary.main}`,
  },
  select: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    flexDirection: "column",
    height: "93%"
  },
  createQueryButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
    marginLeft: "-8px"
  },
  noQueriesButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  dbIcon: {
    "filter": "grayscale(100%)",
    width: 20,
    height: 20,
  },
}));

const MainWindow: FC<React.HTMLAttributes<HTMLDivElement>> = (): JSX.Element => {
  const classes = useStyles();
  const queryService = useContext<Context>(ServicesContext).queryService;
  const databases = queryService.allDatabases!;

  const handleChange = (id: number) => {
    queryService.trySetCurrentDatabase(id);
  }

  if (!queryService.currentDatabase || !queryService.currentDatabase.name) {
    if (!databases)
      return <div></div>;

    return (
      <div className={classes.select}>
        <Typography variant="h6">Select your database</Typography>
          <MenuList>
            {databases && 
              databases.map((d) => 
                <MenuItem button onClick={() => handleChange(d.id)}>
                  <ListItemIcon><img src={getDatabaseIcon(d.databaseType)} className={classes.dbIcon}/></ListItemIcon>
                  <ListItemText primary={d.name} />
                </MenuItem>)
            }
          </MenuList>
      </div>
    );
  }

  return (
      <QueryList
        token={GetTokenForQueryBuilder(queryService.currentDatabase.name)}
      />
  );
};

export default observer(MainWindow);
